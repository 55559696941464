/* 
body, html {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  background: linear-gradient(to right, #1890ff, #91d5ff);
}

.background {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 16px;
}

.content {
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: 400px;
}

.brand-highlight {
  color: #ffffff;
  font-weight: bold;
  text-align: center;
  margin-bottom: 16px;
  font-size: 36px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(90deg, #ff8a00, #e52e71);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.auth-title {
  font-family: 'Roboto', Arial, sans-serif;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin-bottom: 16px;
  font-size: 24px;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

.card-custom {
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.ant-form-item {
  margin-bottom: 16px;
}

.ant-btn-link {
  padding: 0;
  color: #1890ff;
  font-weight: 500;
}

.ant-btn-primary {
  margin-top: 16px;
  background-color: #ff8a00;
  border-color: #ff8a00;
}

.ant-btn-primary:hover {
  background-color: #e52e71;
  border-color: #e52e71;
}

.ant-btn-default {
  background-color: #ffffff;
  border-color: #91d5ff;
  color: #1890ff;
}


@media (max-width: 768px) {
  .ant-layout-header {
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 16px;
  }

  .ant-layout-header .ant-typography {
    margin-bottom: 10px;
  }


  .card-custom {
    padding: 1.5rem;
  }

  .brand-highlight {
    font-size: 28px;
  }

  .auth-title {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .brand-highlight {
    font-size: 24px;
  }

  .auth-title {
    font-size: 18px;
  }

  .card-custom {
    padding: 1rem;
  }

  .ant-btn-primary,
  .ant-btn-default {
    width: 100%;
  }
} */

@charset "utf-8";
/* CSS Document */
@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");

@font-face {
  src: url(./assets/fonts/poppins-font/Poppins-Black.ttf), format("ttf");
}

@font-face {
  src: url(./assets/fonts/poppins-font/Poppins-Bold.ttf), format("ttf");
}

@font-face {
  src: url(./assets/fonts/poppins-font/Poppins-ExtraBold.ttf), format("ttf");
}

@font-face {
  src: url(./assets/fonts/poppins-font/Poppins-ExtraLight.ttf), format("ttf");
}

@font-face {
  src: url(./assets/fonts/poppins-font/Poppins-Light.ttf), format("ttf");
}

@font-face {
  src: url(./assets/fonts/poppins-font/Poppins-Medium.ttf), format("ttf");
}

@font-face {
  src: url(./assets/fonts/poppins-font/Poppins-Regular.ttf), format("ttf");
}

@font-face {
  src: url(./assets/fonts/poppins-font/Poppins-SemiBold.ttf), format("ttf");
}

@font-face {
  src: url(./assets/fonts/poppins-font/Poppins-Thin.ttf), format("ttf");
}

/* rubik font start */
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

@font-face {
  src: url(./assets/fonts/rubik-font/Rubik-Black.ttf), format("ttf");
}
@font-face {
  src: url(./assets/fonts/rubik-font/Rubik-Bold.ttf), format("ttf");
}
@font-face {
  src: url(./assets/fonts/rubik-font/Rubik-ExtraBold.ttf), format("ttf");
}
@font-face {
  src: url(./assets/fonts/rubik-font/Rubik-Italic.ttf), format("ttf");
}
@font-face {
  src: url(./assets/fonts/rubik-font/Rubik-Light.ttf), format("ttf");
}
@font-face {
  src: url(./assets/fonts/rubik-font/Rubik-Medium.ttf), format("ttf");
}
@font-face {
  src: url(./assets/fonts/rubik-font/Rubik-Regular.ttf), format("ttf");
}
@font-face {
  src: url(./assets/fonts/rubik-font/Rubik-SemiBold.ttf), format("ttf");
}
/* rubik font end*/

/** BASIC CSS **/

* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  height: auto;
  max-width: 100%;
  min-width: 100%;
  background: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  font-weight: normal;
  font-style: normal;
  overflow-x: hidden;
}

p {
  font-size: 15px;
  color: #848484;
  line-height: 23px;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #555;
  font-family: "Rubik", sans-serif;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  outline: none;
  color: #4b5460;
}

a:focus {
  outline: none;
}

a:focus {
  outline: none;
}

a:hover,
a:focus {
  color: #24a784;
  text-decoration: none;
}

hr {
  border-top: 1px solid #333;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

::selection {
  text-shadow: none;
  background: #24a784;
  color: #fff;
}

::-moz-selection {
  text-shadow: none;
  background: #24a784;
  color: #fff;
}

a,
input,
select,
textarea,
img {
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

input,
select,
textarea {
  border: 1px solid transparent;
}

.clearfix {
  clear: both;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
}

.hidden {
  display: none !important;
}

.show {
  display: block !important;
}

.floatl {
  float: left;
}

.floatr {
  float: right;
}

.nopad {
  padding: 0 !important;
}

.nopadl {
  padding-left: 0;
}

.nopadr {
  padding-right: 0;
}

.nomar {
  margin: 0;
}

.nomarl {
  margin-left: 0;
}

.nomarr {
  margin-right: 0;
}

.porel {
  position: relative;
}

.main-wrapper {
  width: 100%;
  height: 0 auto;
  overflow: hidden;
}

/** Basic css end here **/
