@charset "utf-8";
/* CSS Document for fixing */

.swal2-styled.swal2-confirm {
    background-color: #AF2330 !important;
}
.swal2-styled.swal2-confirm:focus {
    box-shadow: none !important;
}
.swal2-html-container{
    overflow: hidden !important;
}

/* date 14.09.2024 */
.key-features-section {
    padding: 410px 0px 120px 0px;
}
/*last date 14.09.2024 */

/* date 16.09.2024 */
.key-features-section-list-text ul{
    display: flex;
    justify-content: center;
    margin-bottom: 14px;
    flex-wrap: wrap;
}
.key-features-section-list-text li{
    list-style-type: disc;
    color: #757575;
    font-size: 18px;
    margin: 0px 17px;
    color: #044800;
}
.key-features-section-list-text li::marker{
    color: #044800;
}














