/* General Styles */
body {
  font-family: 'Roboto', sans-serif;
  background-color: #f0f2f5; /* Light background color */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Container Styles */
/* .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  min-height: 100vh;
  background-color: #e5e5e5; 
} */

.paper {
  margin-bottom: 20px;
  padding: 20px;
  width: 100%;
  max-width: 800px; /* Set a max width for better readability */
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.markdown-container {
  margin-top: 20px;
  padding: 20px;
  background-color: none !important;
  border: 1px solid #ddd; /* Clear border for the output response */
  border-radius: 8px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

/* Typography Styles */
.header-link {
  text-decoration: none;
  color: inherit;
  font-size: 18px;
}

.textField,
.dropdown,
.button {
  margin-bottom: 20px;
}

.save-icon {
  float: right;
}

.linearProgress {
  margin-top: 20px;
}

/* Add this to ensure KaTeX formulas are styled correctly */
.katex {
  font-family: 'KaTeX_Main', 'Times New Roman', 'serif';
}

/* Styles for tables */
.markdown-container table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.markdown-container th,
.markdown-container td {
  border: 1px solid #ddd;
  padding: 8px;
}

.markdown-container th {
  background-color: #f2f2f2;
  text-align: left;
}

/* Styles for buttons */
.button {
  background-color: #1890ff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #40a9ff;
}

.icon-button {
  color: #1890ff;
  transition: color 0.3s ease;
}

.icon-button:hover {
  color: #40a9ff;
}

.feedback-button {
  color: #1890ff;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  transition: color 0.3s ease;
}

.feedback-button:hover {
  color: #40a9ff;
}

/* Styles for like and dislike buttons */
.like-button, .dislike-button {
  margin: 0 8px;
  color: #1890ff;
  transition: color 0.3s ease;
}

.like-button:hover, .dislike-button:hover {
  color: #40a9ff;
}

/* Avatar Styles */
.avatar {
  background-color: #87d068;
  cursor: pointer;
}