.content-header {
  margin-bottom: 24px;
  text-align: center;
}

.content-header h2 {
  color: #044800;
  font-size: 28px;
  margin-bottom: 8px;
}

.content-header p {
  color: #666;
  font-size: 16px;
}

.documents-card {
  padding: 0 !important;
  overflow: hidden;
}

.topic-group {
  border-bottom: 1px solid #eee;
}

.topic-group:last-child {
  border-bottom: none;
}

.topic-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background-color: #f8f9fa;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.topic-header:hover {
  background-color: #f0f0f0;
}

.topic-header h3 {
  margin: 0;
  font-size: 18px;
  color: #044800;
  font-weight: 500;
}

.documents-table {
  width: 100%;
}

.table-header {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  padding: 12px 24px;
  font-weight: 500;
  color: #666;
  background-color: #fff;
  font-size: 14px;
  border-bottom: 1px solid #eee;
}

.table-body {
  background: #fff;
}

.table-row {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1fr;
  padding: 16px 24px;
  align-items: center;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s ease;
}

.table-row:hover {
  background-color: #f8f9f8;
}

.table-row:last-child {
  border-bottom: none;
}

.document-type {
  font-weight: 500;
  color: #333;
}

.col-date {
  color: #666;
  font-size: 14px;
}

.col-actions {
  display: flex;
  gap: 12px;
  justify-content: flex-start;
}

.action-btn {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 6px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: white;
  color: #044800;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.action-btn:hover {
  background: #f0f8f0;
  border-color: #044800;
}

.copy-btn {
  padding: 6px;
}

.open-btn {
  text-decoration: none;
  font-weight: 500;
}

.open-btn:hover {
  text-decoration: none;
  color: #044800;
}

.copied-text {
  color: #28a745;
  font-size: 12px;
}

.loading-state {
  padding: 40px;
  text-align: center;
  color: #666;
}

.loader {
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #044800;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin: 0 auto 16px;
}

.no-documents {
  padding: 40px;
  text-align: center;
  color: #666;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (max-width: 768px) {
  .table-header,
  .table-row {
    grid-template-columns: 1fr;
    gap: 8px;
  }

  .table-header {
    display: none;
  }

  .col-type {
    order: 1;
    font-size: 16px;
    margin-bottom: 4px;
  }

  .col-actions {
    order: 2;
    margin-bottom: 8px;
  }

  .col-date {
    order: 3;
  }
}