@charset "utf-8";
/* CSS Document */
@import url("https://fonts.googleapis.com/css?family=Poppins:100,200,300,400,500,600,700,800,900");

@font-face {
  src: url(../fonts/poppins-font/Poppins-Black.ttf), format("ttf");
}

@font-face {
  src: url(../fonts/poppins-font/Poppins-Bold.ttf), format("ttf");
}

@font-face {
  src: url(../fonts/poppins-font/Poppins-ExtraBold.ttf), format("ttf");
}

@font-face {
  src: url(../fonts/poppins-font/Poppins-ExtraLight.ttf), format("ttf");
}

@font-face {
  src: url(../fonts/poppins-font/Poppins-Light.ttf), format("ttf");
}

@font-face {
  src: url(../fonts/poppins-font/Poppins-Medium.ttf), format("ttf");
}

@font-face {
  src: url(../fonts/poppins-font/Poppins-Regular.ttf), format("ttf");
}

@font-face {
  src: url(../fonts/poppins-font/Poppins-SemiBold.ttf), format("ttf");
}

@font-face {
  src: url(../fonts/poppins-font/Poppins-Thin.ttf), format("ttf");
}

/* rubik font start */
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

@font-face {
  src: url(../fonts/rubik-font/Rubik-Black.ttf), format("ttf");
}
@font-face {
  src: url(../fonts/rubik-font/Rubik-Bold.ttf), format("ttf");
}
@font-face {
  src: url(../fonts/rubik-font/Rubik-ExtraBold.ttf), format("ttf");
}
@font-face {
  src: url(../fonts/rubik-font/Rubik-Italic.ttf), format("ttf");
}
@font-face {
  src: url(../fonts/rubik-font/Rubik-Light.ttf), format("ttf");
}
@font-face {
  src: url(../fonts/rubik-font/Rubik-Medium.ttf), format("ttf");
}
@font-face {
  src: url(../fonts/rubik-font/Rubik-Regular.ttf), format("ttf");
}
@font-face {
  src: url(../fonts/rubik-font/Rubik-SemiBold.ttf), format("ttf");
}
/* rubik font end*/

/** BASIC CSS **/

* {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  box-sizing: border-box;
}

html,
body {
  height: auto;
  max-width: 100%;
  min-width: 100%;
  background: #ffffff;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 18px;
  color: #000;
  font-weight: normal;
  font-style: normal;
  overflow-x: hidden;
}

p {
  font-size: 15px;
  color: #848484;
  line-height: 23px;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  color: #555;
  font-family: "Rubik", sans-serif;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  outline: none;
  color: #4b5460;
}

a:focus {
  outline: none;
}

a:focus {
  outline: none;
}

/* a:hover,
a:focus {
  color: #0875F7;
  text-decoration: none;
} */

hr {
  border-top: 1px solid #333;
}

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

::selection {
  text-shadow: none;
  background: #0875F7;
  color: #fff;
}

::-moz-selection {
  text-shadow: none;
  background: #0875F7;
  color: #fff;
}

a,
input,
select,
textarea,
img {
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

input,
select,
textarea {
  border: 1px solid transparent;
}

.clearfix {
  clear: both;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
}

.hidden {
  display: none !important;
}

.show {
  display: block !important;
}

.floatl {
  float: left;
}

.floatr {
  float: right;
}

.nopad {
  padding: 0 !important;
}

.nopadl {
  padding-left: 0;
}

.nopadr {
  padding-right: 0;
}

.nomar {
  margin: 0;
}

.nomarl {
  margin-left: 0;
}

.nomarr {
  margin-right: 0;
}

.porel {
  position: relative;
}

.main-wrapper {
  width: 100%;
  height: 0 auto;
  overflow: hidden;
}

/** Basic css end here **/
/* banner css start */
.main-banner-image {
  position: relative;
}

.main-banner-image::after {
  content: "";
  display: block;
  position: absolute;
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.main-banner-image img {
  width: 100%;
  background-repeat: no-repeat;
  top: 0px;
  height: 780px;
  object-fit: cover;
}

.main-banner-text h1 {
  color: #094805;
  margin-bottom: 9px;
  font-size: 50px;
  font-weight: 600;
}
.main-banner-text p {
  line-height: 28px;
  color: #000;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 20px;
}

.main-banner-btn {
  margin-top: 25px;
}

.solid-btn {
  background: #0875f7;
  color: #fff;
  font-size: 20px;
  padding: 15px 34px;
  display: inline-block;
  margin-right: 12px;
  border: 1px solid transparent;
  transition: 0.6s ease all;
  font-family: "Rubik", sans-serif;
  border-radius: 50px;
  font-weight: 400;
}

.solid-btn:hover {
  background: transparent;
  color: #0875f7;
  border: 1px solid #0875f7;
}

.home-page-main-banner {
  position: relative;
  z-index: 9;
  background: #edffeb;
}
.home-page-main-banner::after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  bottom: -32%;
  background: url(../images/banner-bottom-div-image.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 310px;
}
.home-page-banner-inner-section {
  position: relative;
  z-index: 2;
}
.banner-right-side-image img {
  width: 100%;
  height: 720px;
  object-fit: contain;
}
/* .ant-layout {
  background: #fff !important;
} */
/* banner css end */

/* about-us-section css start */
.about-us-section {
  margin: 340px 0px 120px 0px;
  position: relative;
  z-index: 9;
}
.section-btn {
  background: #0875f7;
  color: #fff;
  font-size: 20px;
  padding: 15px 34px;
  display: inline-block;
  margin-right: 12px;
  transition: 0.6s ease all;
  font-family: "Rubik", sans-serif;
  border-radius: 50px;
  font-weight: 400;
}
.section-btn:hover {
  background: transparent;
  color: #fff;
  background: #094805;
}
.about-us-text {
  text-align: center;
}
.about-us-text h2 {
  color: #044800;
  font-weight: 600;
  font-size: 60px;
  margin-bottom: 15px;
}
.about-us-text p {
  color: #000;
  font-size: 18px;
  line-height: 34px;
  font-weight: 400;
}
.about-us-section-btn {
  text-align: center;
  margin-top: 30px;
}
.about-us-section-background-right-side-circle-image {
  position: absolute;
  right: -15%;
  top: -37%;
}
.about-us-section-background-right-side-circle-image img {
  width: 550px;
  height: 550px;
  object-fit: contain;
  animation: rotate-animation 10s infinite linear;
}
@keyframes rotate-animation {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* about-us-section css end*/

/* key-features-section css start */

.key-features-section {
  margin: 120px 0px;
  background: #edffeb;
  padding: 500px 0px 120px 0px;
  position: relative;
}
.key-features-section::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../images/key-features-top-div-image.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 424px;
}
.key-features-section::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -25%;
  left: 0;
  background: url(../images/key-features-bottom-div-image.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 424px;
}
.key-features-section-title h2 {
  color: #044800;
  font-weight: 600;
  font-size: 60px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.key-features-section-title p {
  color: #757575;
  font-size: 18px;
  line-height: 25px;
}
.key-features-slider-card a {
  background: #21b573;
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  padding: 12px 12px 45px 15px;
  position: relative;
  z-index: 2;
  height: 360px;
}
.key-features-slider-card a::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../images/key-features-slider-card-bottom-wave-image.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  border-radius: 10px;
}
.key-features-slider-section .item {
  padding: 0px 10px;
}
.key-features-slider-card-icon-title {
  display: flex;
  align-items: center;
}
.key-features-slider-icon {
  width: 72px;
  height: 72px;
  display: flex;
  background: #0d7a49;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-right: 15px;
}
.key-features-slider-card-title h5 {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}
.key-features-slider-card-description {
  margin-top: 18px;
}
.key-features-slider-card-description p {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  color: #fff;
  height: 196px;
  overflow-x: hidden;
  padding-right: 10px;
  overflow-y: auto;
}
.key-features-slider-card-description p::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.key-features-slider-card-description p::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}

.key-features-slider-card-description p::-webkit-scrollbar-thumb {
  background-color: #fff;
}
.key-features-slider-card-bottom-icon {
  width: 63px;
  height: 63px;
  background: #fff;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #0d7a49;
  font-size: 24px;
  position: absolute;
  bottom: -18%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.key-features-slider-section .slick-active {
  opacity: 1 !important;
}

.key-features-slider-section .slick-list {
  overflow: initial !important;
}
.key-features-slider-section .slick-slide {
  opacity: 0;
  transition: opacity 0.75s linear;
}
.key-features-slider-section .slick-prev:before {
  display: none;
}
.key-features-slider-section .slick-next:before {
  display: none;
}
.key-features-slider-section .slick-prev {
  right: 33% !important;
  top: -46% !important;
  left: inherit !important;
  width: 63px;
  height: 63px;
  background: #fff !important;
  border: 2px solid #217c58;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #0d7a49;
  font-size: 23px;
  border-radius: 50px;
  transition: 0.6s ease all;
}
.key-features-slider-section .slick-next {
  right: 24% !important;
  top: -46% !important;
  width: 63px;
  height: 63px;
  background: #fff !important;
  border: 2px solid #217c58;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #0d7a49;
  font-size: 23px;
  border-radius: 50px;
  transition: 0.6s ease all;
}
.key-features-slider-section .slick-prev:hover {
  background: #0875f7 !important;
  color: #fff !important;
  border: 2px solid #0875f7 !important;
}
.key-features-slider-section .slick-next:hover {
  background: #0875f7 !important;
  color: #fff !important;
  border: 2px solid #0875f7 !important;
}
.key-features-slider-section {
  margin-top: 90px;
  margin-left: 17%;
  margin-right: -6%;
  position: relative;
  z-index: 99;
}
.key-features-slider-section .key-feature-col-section {
  padding: 0px;
}
.key-features-slider-icon img{
	width: 54px;
	height: 54px;
	object-fit: contain;
}
/* key-features-section css end*/

/* contact-us-section css start */
.contact-us-section {
  position: relative;
  z-index: 9;
  margin: 165px 0px 120px 0px;
  padding: 120px 0px;
}
.contact-us-section::after {
  content: "";
  position: absolute;
  display: block;
  left: 0;
  top: -44%;
  background: url(../images/get-in-touch-section-background-image.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  width: 1144px;
  height: 1802px;
}
.contact-us-section-image img {
  width: 100%;
  height: 624px;
  object-fit: contain;
}
.contact-us-right-section-title h2 {
  color: #044800;
  font-weight: 600;
  font-size: 60px;
  margin-bottom: 15px;
  text-transform: uppercase;
}
.contact-us-right-section-title p {
  color: #757575;
  font-size: 18px;
  line-height: 25px;
}
.text-btn {
  color: #21b573;
  font-weight: 500;
}
.text-btn:hover {
  color: #044800;
}
.contact-from-label-input-section input {
  width: 100%;
  padding: 20px 22px;
  border: 1px solid #ababab;
  background: #fafffd;
  border-radius: 10px;
  font-size: 18px;
  color: #000;
  font-weight: 500;
}
.contact-from-label-input-section input::placeholder {
  color: #737070;
}
.contact-from-label-input-section textarea {
  width: 100%;
  padding: 20px 22px;
  border: 1px solid #ababab;
  background: #fafffd;
  border-radius: 10px;
  font-size: 18px;
  color: #000;
  font-weight: 500;
}
.contact-from-label-input-section textarea::placeholder {
  color: #737070;
}
.contact-us-submit-btn {
  margin-top: 18px;
  display: inline-block;
  position: relative;
}
.contact-us-submit-btn i {
  position: absolute;
  left: 132px;
  top: 20px;
  color: #fff;
  font-size: 16px;
}
.contact-us-submit-btn button {
  background: #0875f7;
  color: #fff;
  font-size: 20px;
  padding: 18px 56px 18px 46px;
  display: inline-block;
  margin-right: 12px;
  border: 1px solid transparent;
  transition: 0.6s ease all;
  font-family: "Rubik", sans-serif;
  border-radius: 50px;
  font-weight: 400;
  transition: 0.6s ease all;
}
.contact-us-submit-btn button:hover {
  background: #044800;
}
.contact-from-label-input-section label {
  color: #28ac20;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 12px;
}
.contact-from-label-input-section {
  margin-bottom: 22px;
}
.contact-from-field-section {
  margin-top: 30px;
}
/* contact-us-section css end*/
/* footer css start */
.footer {
  margin: 120px 0px 0px 0px;
  padding: 300px 0px 0px 0px;
  background: #1f331e;
  position: relative;
  z-index: 9;
}
.footer::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: url(../images/footer-top-div-image.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 250px;
}
.footer-logo {
  text-align: center;
}
.footer-logo img {
  width: 252px;
}
.footer-quick-link {
  border-top: 1px solid #4c5c4b;
  border-bottom: 1px solid #4c5c4b;
  padding: 30px 0px;
  margin: 30px 0px;
}
.footer-quick-link ul {
  text-align: center;
}
.footer-quick-link ul li {
  display: inline-block;
}
.footer-quick-link ul li a {
  font-size: 20px;
  font-weight: 400;
  display: inline-block;
  margin: 0px 35px;
  color: #fff;
}
.footer-quick-link ul li a:hover {
  color: #0875f7;
}
.footer-active-link {
  color: #0875f7 !important;
}
.footer-support-email a {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-support-mail-icon {
  width: 52px;
  height: 52px;
  border-radius: 50px;
  background: #fff;
  color: #0875f7;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  transition: 0.6s ease all;
}
.footer-support-mail-text h5 {
  font-size: 20px;
  font-weight: 400;
  color: #fff;
}
.footer-support-email a:hover .footer-support-mail-icon {
  background: #0875f7;
  color: #fff;
}
.footer-copy-right-section {
  background: #192818;
  margin-top: 40px;
  padding: 25px 0px;
}
.footer-copy-right-text {
  text-align: center;
}
.footer-copy-right-text p {
  margin-bottom: 0px;
  color: #fff;
  font-size: 18px;
}
/* footer css end */
/* login-from-section css start */
.login-form-section{
  margin: 120px 0px;
  position: relative;
  z-index: 9;
}
.login-from-background-image{
  position: absolute;
  right: 0;
  top: 0;
}
.login-form-card{
  border: none !important;
  background: none !important;
}
.login-from-image-total-section{
  background: #fff;
  border: 1px solid #8c8c8c;
  border-radius: 20px;
  padding: 10px;
}
.login-form-right-side-image img{
  width: 100%;
  height: 638px;
  object-fit: contain;
}
.login-form-right-side-image{
  background: #F4F4F4;
  border-radius: 15px;
}
.login-form-card{
  padding: 0px 30px;
}
.login-from-card-title{
  color: #044800 !important;
  font-size: 50px !important;
  font-weight: 600 !important;
  font-family: "Rubik", sans-serif !important;
  margin-bottom: 30px !important;
}
.login-form-card h3{
  font-size: 25px !important;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 34px !important;
}
.login-form-input-section .login-form-input{
  background: #FAFFFD !important;
  width: 100% !important;
  color: #000 !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 18px !important;
  border: 1px solid #ABABAB;
  padding: 15px 14px 15px 50px !important;
  border-radius: 10px !important;
}
.login-form-input-section .ant-input::placeholder{
  color: #ADA4A5 !important;
}
.login-form-input-section{
  padding-bottom: 9px;
  position: relative;
}
.input-field-icon{
  position: absolute;
  left: 17px;
  top: 17px;
  z-index: 2;
}
.input-field-icon i{
  color: #7B6F72;
  font-size: 24px;
}
.login-from-forgot-password-btn{
  color: #0875F7 !important;
  font-size: 17px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  text-decoration: underline !important;
  display: block;
  text-align: left;
  margin-left: 0px;
  padding-left: 0px;
}

.login-from-forgot-password-btn span{
  text-decoration: underline !important;
}
.login-from-login-btn{
  display: inline-block !important;
  width: 100%;
  padding: 13px 25px !important;
  height: inherit;
  border-radius: 50px;
  font-size: 20px;
  font-family: "Rubik", sans-serif !important;
  position: relative;
  transition: 0.4s ease all;
}
.login-from-login-btn:hover{
  background: #044800 !important;
}
.login-from-login-btn i{
  position: relative;
  top: 2px;
  left: 6px;
}
.create-acount-google-sign-in-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin-top: 30px;
}
.create-acount-google-sign-in-btn::after{
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1px;
  height: 39px;
  background: #BCBCBC;
}
.create-acount-btn{
  color: #0875F7 !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
  text-decoration: underline !important;
  display: block;
  text-align: left;
  margin: 0px !important;
  padding: 0px !important;
}
.create-acount-btn span{
  text-decoration: underline !important;
}
.google-sign-in-btn{
  color: #616161 !important;
  font-size: 17px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 500 !important;
  margin: 0px !important;
  padding: 0px !important;
  border: none !important;
  display: block;
  text-align: right;
  background: none;
  box-shadow: none;
}
.login-from-background-image {
  position: absolute;
  right: -22%;
  top: -68px;
  z-index: -1;
}
.login-from-background-image img{
  width: 1003px;
  height: 842px;
  object-fit: contain;
  animation: rotate-animation 10s infinite linear;
}
.ant-modal .ant-modal-footer {
  text-align: center;
}
.ant-modal .ant-modal-content {
  margin-top: 50px;
}
/* login-from-section css end */

/* views-card-section css start */

.views-card-section{
  margin: 120px 0px;
  position: relative;
  z-index: 9;
}
.ant-layout {
  background: none;
}
.views-welcome-title-text{
  color: #044800 !important;
  font-size: 50px !important;
  font-weight: 600 !important;
  font-family: "Rubik", sans-serif !important;
  margin-bottom: 18px !important;
}
.title-bottom-text{
  font-size: 25px !important;
  font-weight: 500 !important;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 34px !important;
  color: #000 !important;
}
.card-title-text{
  font-size: 20px !important;
  font-weight: 500 !important;
  color: #fff !important;
  font-family: "Rubik", sans-serif !important;
  text-wrap: wrap;
}
.card-paragraph-text{
  font-size: 18px !important;
  font-weight: 400 !important;
  line-height: 25px !important;
  color: #fff !important;
  font-family: "Poppins", sans-serif !important;
  margin-bottom: 0px !important;
  margin-top: 18px;
}
.view-cards{
  background: #21b573 !important;
  display: inline-block;
  width: 100%;
  border-radius: 10px;
  padding: 12px 12px 66px 15px;
  position: relative;
  z-index: 2;
}
.view-card-call-section{
  margin-bottom: 56px;

}
.ant-card .ant-card-body {
  padding: 0px !important;
}
.view-cards::before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../images/key-features-slider-card-bottom-wave-image.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
  border-radius: 10px;
}
.view-cards .key-features-slider-card-bottom-icon {
  bottom: -19%;
}
/* views-card-section css end */

/* additionalinfo-form-section css start */
.additionalinfo-form-section .login-form-input-section {
  padding-bottom: 0px !important;
}
.ant-select-single {
  font-size: inherit !important;
  height: inherit !important;
}
.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border: none !important;
  background: none !important;
  width: 100%;
  height: 100%;
  padding: 15px 0px 15px 15px;
}
.detail-form-section .login-form-input {
  padding: 15px 15px 15px 15px !important;
}
.detail-form-section .ant-select-arrow {
  width: 50px;
  height: 50px;
  background: #0875F7;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  top: 18%;
  right: 6px;
  color: #fff;
  font-weight: 700;
  font-size: 18px;
}
.login-form-select-input{
  background: #FAFFFD !important;
  width: 100% !important;
  color: #000 !important;
  font-family: "Poppins", sans-serif !important;
  font-size: 18px !important;
  border: 1px solid #ABABAB;
  border-radius: 10px !important;
}
.login-form-select-input .ant-select-selection-item{
  font-size: 18px !important;

}
.login-form-select-input::placeholder{
  color: #000;
}
.details-goback-btn{
  background: none;
  color: #0875F7;
  font-family: "Rubik", sans-serif !important;
  font-size: 20px;
  font-weight: 500;
  box-shadow: none !important;
  transition: 0.4s ease all;
  padding: 0px;
}
.details-goback-btn:hover{
  color: #044800 !important;
}
.detail-form-section .login-form-card {
  padding: 0px 0px !important;
}
/* additionalinfo-form-section css end */

/* user login css start */

.ant-dropdown {
  z-index: 9999;
}
.user-signin-logout-section-responsive{
  display: none;
  margin-right: 15px;
}
.ant-avatar {
  width: 38px;
  height: 38px;
}
/* user login css end*/

/* generate section css start */
.detail-form-section .login-from-login-btn{
  box-shadow: rgba(22, 119, 255, 0.2) 0px 7px 29px 0px;
}

/* response card css start */
.css-bhp9pd-MuiPaper-root-MuiCard-root {
  background-color: none !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  overflow: hidden;
}
.query-text{
  color: #000;
  font-size: 28px !important;
  font-family: "Poppins", sans-serif !important;
  font-weight: 600;
}
.markdown-container {
  border: none;
}
.export-text-export-drive-btn{
  text-align: right;
}
.query-text-export-btn-section{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 22px;

}
.export-text-export-drive-btn button{
  font-size: 20px !important;
  font-family: "Rubik", sans-serif !important;
  text-transform: none !important;
  padding: 15px 30px;
  background: #DCECFF;
  border-radius: 50px;
  color: #0875F7;
  transition: 0.6 ease all;
}
.export-text-export-drive-btn button:hover{
  background: #044800;
  color: #fff;
}
.ask-praro-feedback-btn .feedback-button{
  font-size: 20px !important;
  font-family: "Rubik", sans-serif !important;
  text-transform: none !important;
  padding: 15px 125px;
  background: #DCECFF;
  border-radius: 50px;
  color: #0875F7;
  transition: 0.6 ease all !important;
  margin-right: 40px;
  line-height: inherit !important;
}
.ask-praro-feedback-btn .feedback-button:hover{
  background: #044800;
  color: #fff;
}
.ask-praro-feedback-btn .ask-praro-btn{
  font-size: 20px !important;
  font-family: "Rubik", sans-serif !important;
  text-transform: none !important;
  padding: 15px 125px;
  background: #0875F7;
  border-radius: 50px;
  color: #fff;
  transition: 0.6 ease all !important;
  box-shadow: none !important;
  font-weight: 400;
  line-height: inherit !important;
  margin-top: -6px;
}
.ask-praro-feedback-btn .ask-praro-btn:hover{
  background: #044800;
  color: #fff;
}
.ask-praro-feedback-btn{
  text-align: center;
  margin-top: 35px;
}
.response-page .login-form-card {
  padding: 0px 0px;
}
/* response card css end */

.css-bhp9pd-MuiPaper-root-MuiCard-root {
  background-color: none !important;
}
.markdown-container {
  background-color: none !important;
}

.key-features-slider-section .view-cards{
  height: 310px !important;
}
.dialog-cancel-btn{
  text-align: right;
  margin-right: 5px;
  margin-top: 7px;
  position: absolute;
  right: 0;
}
.dialog-cancel-btn button{
  color: #ff0000;
  background: none;
  font-size: 22px;
  display: inline-block;
}